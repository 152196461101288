.root {
    height: 100vh;
    width: 100vw;
    background-color: black;
    margin: 0rem;
    overflow: hidden;
  }
   
  .image-track {
    display: flex;
    gap: 4vmin;
    position: relative;
    left: 50%;
    top: 50%;
  }
  
  .image-track .image {
    transition: width 0.5s linear, height 0.5s linear;
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% 50%;

  }

  .image.expanded {
    transform: translateY(-7.5%);
    width: 60vw;
    height: 70vh;
  }